<template>
	<div>
		<multiselect :placeholder="$t('select')" v-model="selected" :options="options"
			:class="validateError != '' ? 'box-border-color' : ''" @input="handleInput($event)" label="text"
			track-by="value" :multiple="false" :select-label="''" :selected-label="''" :deselect-label="''"
			:searchable="true">
			<span slot="noOptions">
				{{ $t('no_options') }}
			</span>
			<span slot="noResult">
				{{ $t('no_result') }}
			</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
	</div>
</template>

<script>
// Helpers
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import MedicinegroupService from "@/services/MedicinegroupService";

export default {
	props: {
		value: {
			default: null
		},
		valueType: {
			type: String,
			default: 'id',
		},
		validateError: {
			type: String,
			default: ''
		}
	},
	watch: {
		value: function (newValue) {
			this.selected = this.setSelected(newValue, this.options);
		},
	},
	data() {
		return {
			selected: null,
			options: [],
		}
	},
	created() {
		this.getOptions();
	},
	methods: {
		setSelected: setSelected,
		handleInput: handleInput,
		getOptions() {
			const key = `medicine_group_values`


			let local = this.getWithExpiry(key);
			if (local) {
				this.setData(local)
				return
			}

			const config = {
				params: {
					sort: this.getLocaleField('name'),
					limit: -1
				},
			};
			MedicinegroupService.getAll(config)
				.then(response => {
					const data = response.data.data;
					this.setData(data)
					this.setWithExpiry(key, data);
				})
				.then(() => {
					this.selected = this.setSelected(this.value, this.options);
				})
		},
		setData(data) {
			data.map(item => {
				this.options.push({
					value: item[this.valueType],
					text: item.name,
				});
			});
		}
	}
}
</script>
